<template>
  <div class="container">
    <div class="lineH-box">
      <div class="lineH"></div>
      <div>自提地址</div>
    </div>
    <el-form
      :model="form"
      style="min-width: 1000px"
      label-width="100px"
      :rules="rules"
      ref="ruleForm"
    >
      <el-form-item label="启用自提">
        <!-- <el-checkbox v-model="form.delivery_self">启用自提</el-checkbox> -->
        <el-switch v-model="form.delivery_self"> </el-switch>
      </el-form-item>
      <el-form-item label="自提地址" prop="district">
        <el-cascader
          v-model="form.district"
          :options="goodsTypeData"
          placeholder="选择地区"
          :props="goodsTypeSet"
          style="width: 370px"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 10 }"
          placeholder="详细地址"
          v-model="form.address"
          style="width: 370px"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item label="自提时间" prop="endTime">
        <el-time-select
          placeholder="开始时间"
          v-model="form.startTime"
          :picker-options="{
            start: '00:00',
            step: '00:10',
            end: '24:00',
          }"
        ></el-time-select>
        <span style="margin: 0 8px">~</span>
        <el-time-select
          placeholder="结束时间"
          v-model="form.endTime"
          :picker-options="{
            start: '00:00',
            step: '00:10',
            end: '24:00',
            minTime: form.startTime,
          }"
        ></el-time-select>
        <div class="gray">选择每日自提时间段，自提截止时间需晚于起始时间</div>
      </el-form-item>
    </el-form>

    <div class="lineH-box">
      <div class="lineH"></div>
      <div>退货地址</div>
    </div>
    <el-form
      :model="form"
      style="min-width: 1000px"
      label-width="100px"
      :rules="rules"
      ref="ruleForm"
    >
      <el-form-item label="联系人" prop="service_name">
        <el-input v-model="form.service_name" style="width: 370px"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="service_phone">
        <el-input v-model="form.service_phone" style="width: 370px"></el-input>
      </el-form-item>

      <el-form-item label="自提地址" prop="service_district">
        <el-cascader
          v-model="form.service_district"
          :options="goodsTypeData"
          placeholder="选择地区"
          :props="goodsTypeSet"
          style="width: 370px"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="service_address">
        <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 10 }"
          placeholder="详细地址"
          v-model="form.service_address"
          style="width: 370px"
          maxlength="50"
          show-word-limit
        ></el-input>
        <!-- 默认地址 -->
        <div class="gray">默认退货地址为用户寄回货品的商家地址</div>
      </el-form-item>
    </el-form>

    <div class="flex center">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
  </div>
</template>
<script>
import commonUtil from "../../../utils/commonUtil.js";
export default {
  data() {
    let checkDistrict = (rule, value, callback) => {
      if (!this.form.delivery_self) {
        callback();
      } else if (value.length == 0) {
        console.log("不满足1");
        return callback(new Error("请选择地区"));
      } else {
        callback();
      }
    };
    let checkAddress = (rule, value, callback) => {
      if (!this.form.delivery_self) {
        callback();
      } else if (!value) {
        console.log("不满足2");
        return callback(new Error("请输入详细地址"));
      } else {
        callback();
      }
    };
    let checkEndTime = (rule, value, callback) => {
      if (!this.form.delivery_self) {
        callback();
      } else if (!(value && this.form.startTime)) {
        console.log("不满足3");
        return callback(new Error("请选择时间"));
      } else {
        callback();
      }
    };
    let serviceCheckDistrict = (rule, value, callback) => {
      if (value.length == 0) {
        console.log("不满足1");
        return callback(new Error("请选择地区"));
      } else {
        callback();
      }
    };
    let serviceCheckAddress = (rule, value, callback) => {
      if (!value) {
        console.log("不满足2");
        return callback(new Error("请输入详细地址"));
      } else {
        callback();
      }
    };
    let serviceCheckName = (rule, value, callback) => {
      if (!value) {
        console.log("不满足2");
        return callback(new Error("请输入联系人"));
      } else {
        callback();
      }
    };
    let serviceCheckPhone = (rule, value, callback) => {
      if (!value) {
        console.log("不满足2");
        return callback(new Error("请输入联系电话"));
      } else {
        callback();
      }
    };
    return {
      form: {
        delivery_self: false,
        district: [],
        address: "",
        startTime: "",
        endTime: "",
        service_name: "",
        service_phone: "",
        service_district: [],
        service_address: "",
      },
      rules: {
        district: [{ validator: checkDistrict, trigger: "blur" }],
        address: [{ validator: checkAddress, trigger: "blur" }],
        endTime: [{ validator: checkEndTime, trigger: "blur" }],

        service_name: [{ validator: serviceCheckName, trigger: "blur" }],
        service_phone: [{ validator: serviceCheckPhone, trigger: "blur" }],
        service_district: [
          { validator: serviceCheckDistrict, trigger: "blur" },
        ],
        service_address: [{ validator: serviceCheckAddress, trigger: "blur" }],
      },
      goodsTypeData: [],
      goodsTypeSet: {
        value: "value",
        label: "label",
        children: "children",
      },
    };
  },

  methods: {
    //获取药房设置信息
    getSetInfo() {
      this.axios.post("/store/Shopshipping/getShopDeliveryType").then((res) => {
        let info = res.data.info;
        let data = {
          delivery_self: info.delivery_self == 1 ? true : false,
          address: info.address,
          service_district: [
            info.service_province_id,
            info.service_city_id,
            info.service_district_id,
          ],
          service_address: info.service_address,
          service_name: info.service_name,
          service_phone: info.service_phone,
        };

        if (info.district_id) {
          data.district = [info.province_id, info.city_id, info.district_id];
        }
        if (info.time_slot) {
          data.startTime = info.time_slot.split(",")[0];
          data.endTime = info.time_slot.split(",")[1];
        }

        this.form = data;
      });
    },
    //获取地区信息
    getCityData() {
      this.axios.post("/store/platform/getProvinceCityDistrict").then((res) => {
        if (res.code == 0) {
          let tree = res.data.list;
          this.transRegions(tree);
          this.goodsTypeData = tree;
        }
      });
    },
    transRegions(tree) {
      tree.forEach((item) => {
        if (item.city || item.district) {
          item.children = item.city || item.district;
          delete item.city;
          delete item.district;
          this.transRegions(item.children);
        }
        if (item.province_id) {
          item.value = item.province_id;
          delete item.province_id;
        }
        if (item.city_id) {
          item.value = item.city_id;
          delete item.city_id;
        }
        if (item.district_id) {
          item.value = item.district_id;
          delete item.district_id;
        }
        if (item.name) {
          item.label = item.name;
          delete item.name;
        }
      });
    },
    cancel() {
      commonUtil.lostFocus(event);
      this.getCityData();
      this.getSetInfo();
    },
    submit() {
      commonUtil.lostFocus(event);
      let check = false;
      this.$refs.ruleForm.validate((valid) => {
        check = valid || false;
      });
      if (!check) {
        return;
      }
      let data = {
        delivery_self: this.form.delivery_self == 1 ? true : false,
        address: this.form.address,
        service_province_id: this.form.service_district[0],
        service_city_id: this.form.service_district[1],
        service_district_id: this.form.service_district[2],
        service_address: this.form.service_address,
        service_name: this.form.service_name,
        service_phone: this.form.service_phone,
      };
      if (data.delivery_self) {
        data.time_slot = this.form.startTime + "," + this.form.endTime;
        data.province_id = this.form.district[0];
        data.city_id = this.form.district[1];
        data.district_id = this.form.district[2];
      }
      console.log(data);
      this.axios
        .post("/store/Shopshipping/editShopDeliveryType", data)
        .then((res) => {
          this.$message.success("修改成功");
          this.getSetInfo();
        });
    },
  },
  created() {
    this.getCityData();
    this.getSetInfo();
  },
};
</script>
<style lang="scss" scoped>
.lineH-box {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #606266;
  font-weight: 700;
  margin-bottom: 20px;
  .lineH {
    width: 3px;
    height: 18px;
    background: #4974f5;
    border-radius: 21px;
    margin-right: 8px;
  }
}
.title {
  font-weight: 600;
  font-size: 16px;
  margin: 18px 0;
}
.item {
  padding-left: 50px;
}
.gray {
  color: #999;
  margin-top: 10px;
}
</style>