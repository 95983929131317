var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_vm._m(0),_c('el-form',{ref:"ruleForm",staticStyle:{"min-width":"1000px"},attrs:{"model":_vm.form,"label-width":"100px","rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"启用自提"}},[_c('el-switch',{model:{value:(_vm.form.delivery_self),callback:function ($$v) {_vm.$set(_vm.form, "delivery_self", $$v)},expression:"form.delivery_self"}})],1),_c('el-form-item',{attrs:{"label":"自提地址","prop":"district"}},[_c('el-cascader',{staticStyle:{"width":"370px"},attrs:{"options":_vm.goodsTypeData,"placeholder":"选择地区","props":_vm.goodsTypeSet},model:{value:(_vm.form.district),callback:function ($$v) {_vm.$set(_vm.form, "district", $$v)},expression:"form.district"}})],1),_c('el-form-item',{attrs:{"label":"详细地址","prop":"address"}},[_c('el-input',{staticStyle:{"width":"370px"},attrs:{"type":"textarea","autosize":{ minRows: 3, maxRows: 10 },"placeholder":"详细地址","maxlength":"50","show-word-limit":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('el-form-item',{attrs:{"label":"自提时间","prop":"endTime"}},[_c('el-time-select',{attrs:{"placeholder":"开始时间","picker-options":{
          start: '00:00',
          step: '00:10',
          end: '24:00',
        }},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}}),_c('span',{staticStyle:{"margin":"0 8px"}},[_vm._v("~")]),_c('el-time-select',{attrs:{"placeholder":"结束时间","picker-options":{
          start: '00:00',
          step: '00:10',
          end: '24:00',
          minTime: _vm.form.startTime,
        }},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}}),_c('div',{staticClass:"gray"},[_vm._v("选择每日自提时间段，自提截止时间需晚于起始时间")])],1)],1),_vm._m(1),_c('el-form',{ref:"ruleForm",staticStyle:{"min-width":"1000px"},attrs:{"model":_vm.form,"label-width":"100px","rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"联系人","prop":"service_name"}},[_c('el-input',{staticStyle:{"width":"370px"},model:{value:(_vm.form.service_name),callback:function ($$v) {_vm.$set(_vm.form, "service_name", $$v)},expression:"form.service_name"}})],1),_c('el-form-item',{attrs:{"label":"联系电话","prop":"service_phone"}},[_c('el-input',{staticStyle:{"width":"370px"},model:{value:(_vm.form.service_phone),callback:function ($$v) {_vm.$set(_vm.form, "service_phone", $$v)},expression:"form.service_phone"}})],1),_c('el-form-item',{attrs:{"label":"自提地址","prop":"service_district"}},[_c('el-cascader',{staticStyle:{"width":"370px"},attrs:{"options":_vm.goodsTypeData,"placeholder":"选择地区","props":_vm.goodsTypeSet},model:{value:(_vm.form.service_district),callback:function ($$v) {_vm.$set(_vm.form, "service_district", $$v)},expression:"form.service_district"}})],1),_c('el-form-item',{attrs:{"label":"详细地址","prop":"service_address"}},[_c('el-input',{staticStyle:{"width":"370px"},attrs:{"type":"textarea","autosize":{ minRows: 3, maxRows: 10 },"placeholder":"详细地址","maxlength":"50","show-word-limit":""},model:{value:(_vm.form.service_address),callback:function ($$v) {_vm.$set(_vm.form, "service_address", $$v)},expression:"form.service_address"}}),_c('div',{staticClass:"gray"},[_vm._v("默认退货地址为用户寄回货品的商家地址")])],1)],1),_c('div',{staticClass:"flex center"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("保存")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lineH-box"},[_c('div',{staticClass:"lineH"}),_c('div',[_vm._v("自提地址")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lineH-box"},[_c('div',{staticClass:"lineH"}),_c('div',[_vm._v("退货地址")])])}]

export { render, staticRenderFns }